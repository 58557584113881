<template>
  <div class="container">
    <div class="user-nav">
      <el-row type="flex" justify="space-between">
        <el-col :span="8"></el-col>
        <el-col :span="16" style="text-align: right">
          <div class="userinfo">
            <p>{{ paramsData.userName }}</p>
            <span>学籍号：{{ paramsData.studentCode }}</span>
            <span>{{ paramsData.className }}</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="main">
      <div class="table-box">
        <h4>社会实践</h4>
        <table border="1" cellspacing="0" rules="all">
          <tr>
            <th>项目</th>
            <th>设计制作</th>
            <th>劳动体验</th>
            <th>社会服务</th>
            <th>素质拓展</th>
            <th>考察探究</th>
            <th>总计</th>
          </tr>
          <tr>
            <td>数据条数</td>
            <td>{{ socialForm.designCount }}</td>
            <td>{{ socialForm.workCount }}</td>
            <td>{{ socialForm.serviceCount }}</td>
            <td>{{ socialForm.qualityCount }}</td>
            <td>{{ socialForm.inspectCount }}</td>
            <td>{{ socialForm.sumCount }}</td>
          </tr>
          <tr>
            <td>综素成绩（分）</td>
            <td>{{ socialForm.designScore }}</td>
            <td>{{ socialForm.workScore }}</td>
            <td>{{ socialForm.serviceScore }}</td>
            <td>{{ socialForm.qualityScore }}</td>
            <td>{{ socialForm.inspectScore }}</td>
            <td>{{ socialForm.sumScore }}</td>
          </tr>
        </table>
      </div>

      <div class="more">
        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <h4>相关记录</h4>
          </el-col>
        </el-row>
        <el-table
          :data="socialList"
          style="width: 100%"
          border
          :header-cell-style="tableHeaderColor"
          :cell-style="cellStyleFun"
        >
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="practiceTitle" label="社会实践主题">
          </el-table-column>
          <el-table-column prop="practiceLabel" label="指标标签">
          </el-table-column>
          <el-table-column prop="practiceType" label="实践类别">
          </el-table-column>
          <el-table-column prop="practiceAddress" label="实践地点">
          </el-table-column>
          <el-table-column prop="practiceTime" label="实践时间">
          </el-table-column>
          <el-table-column prop="submitStatus" label="心得感悟">
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                @click="handleDetailSocial(scope.row)"
                type="text"
                size="small"
                >查看详情</el-button
              >
              <el-button
                @click="handleUnderstand(scope.row)"
                type="text"
                size="small"
                >心得感悟</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="back">
        <el-button type="text" @click="backIndex">返回</el-button>
      </div>
    </div>

    <!-- 查看详情 -->
    <el-dialog
      title="团体社会实践详情"
      :visible.sync="open"
      width="600px"
      center
    >
      <el-form
        ref="detailForm"
        :model="detailForm"
        style="
          border-top: 1px solid #e1e3e9;
          border-bottom: 1px solid #e1e3e9;
          padding: 5px 0 10px 0;
        "
      >
        <el-form-item label="社会实践主题：">
          <span class="scontent">{{ detailForm.practiceTitle }}</span>
        </el-form-item>
        <el-form-item label="指标标签：">
          <span class="scontent">{{ detailForm.practiceLabel }}</span>
        </el-form-item>
        <el-form-item label="实践时间：">
          <span class="scontent">{{ detailForm.practiceTime }}</span>
        </el-form-item>
        <el-form-item label="实践地点：">
          <span class="scontent">{{ detailForm.practiceAddress }}</span>
        </el-form-item>
        <el-form-item label="参与学生：">
          <el-button size="mini" type="primary" @click="innerVisible = true"
            >查看</el-button
          >
          <el-dialog
            width="500px"
            title="参与学生"
            :visible.sync="innerVisible"
            append-to-body
            center
          >
            <div class="dialog-name">
              <h3>学生名单（共{{ detailForm.userNames.length }}人）</h3>
              <ul style="display: flex; flex-wrap: wrap">
                <li
                  v-for="(item, index) in detailForm.userNames"
                  :key="index"
                  style="
                    font-size: 14px;
                    color: #303133;
                    line-height: 48px;
                    margin-right: 16px;
                  "
                >
                  {{ item }}
                </li>
              </ul>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button @click="innerVisible = false">关 闭</el-button>
            </div>
          </el-dialog>
        </el-form-item>
        <el-form-item label="实践图片：">
          <el-image
            v-for="(item, index) in detailForm.practiceImg"
            :key="index"
            fit="contain"
            :src="imgSrc + item"
          ></el-image>
        </el-form-item>
		<el-divider content-position="left">实践内容描述</el-divider>
		<div v-html="detailForm.practiceContext"></div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="open = false">关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 心得感悟 -->
    <el-dialog title="心得感悟" :visible.sync="openPub" center width="600px">
      <el-form ref="form" :model="form">
        <el-form-item>
          <span class="sname">{{ form.userName }}</span>
          <span class="snumb">学籍号：{{ form.userNum }}</span>
        </el-form-item>
        <el-form-item label="社会实践主题：">
          <span class="scontent">{{ form.practiceTitle }}</span>
        </el-form-item>
        <el-form-item>
          <el-image
            v-for="(item, index) in form.perImg"
            :key="index"
            fit="contain"
            :src="imgSrc + item"
          ></el-image>
        </el-form-item>
        <el-form-item label="基地评分：" v-if="form.practiceType == '0'">
          <el-rate v-model="form.baseScore" :disabled="true" style="margin-top: 10px"></el-rate>
        </el-form-item>
        <el-form-item label="审核状态：">
          <span class="scontent">{{ form.verifyStatus }}</span>
        </el-form-item>
		<el-divider content-position="left">心得感悟</el-divider>
		<div v-html="form.perContext"></div>
        <el-form-item label="驳回原因：" v-if="form.verifyStatus == '已驳回'">
          <span class="scontent" style="color: red">{{
            form.rejectCause
          }}</span>
        </el-form-item>
      </el-form>
      <el-dialog
        width="500px"
        title="驳回原因"
        :visible.sync="innerVisiblePub"
        append-to-body
        center
      >
        <el-form ref="form" :model="form">
          <el-form-item label="驳回原因：">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
              placeholder="驳回原因"
              v-model="form.rejectCause"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="扣分值：">
            <el-select v-model="form.userCreditReduceScore" placeholder="请选择">
              <el-option label="5分" value="5"> </el-option>
              <el-option label="4分" value="4"> </el-option>
              <el-option label="3分" value="3"> </el-option>
              <el-option label="2分" value="2"> </el-option>
              <el-option label="1分" value="1"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="innerVisiblePub = false">关 闭</el-button>
          <el-button @click="handlereject" type="primary">确 定</el-button>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="openPub = false">关 闭</el-button>
        <el-button
          @click="innerVisiblePub = true"
          type="primary"
          v-if="form.verifyStatus == '已通过'"
          >驳回</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getResultDetail,
  getResultById,
  getSocialDetail,
  getExperienceDetail,
  rejectExperience,
} from "@/api/teacher/quality/social";
export default {
  data() {
    return {
      imgSrc: this.imageSrc(),
      paramsData: {},
      socialForm: {},
      socialList: [],
      detailForm: {
        userNames: [],
      },
      open: false,
      innerVisible: false,
      openPub: false,
      innerVisiblePub: false,
      form: {},
      rowForm: {},
    };
  },
  created() {
    this.paramsData =
      this.$route.params.userName != undefined
        ? this.$route.params
        : JSON.parse(sessionStorage["params"]);
    this.getList();
  },
  methods: {
    getList() {
      getResultDetail({ userId: this.paramsData.userId }).then((res) => {
        this.socialForm = res.data;
      });
      getResultById({ userId: this.paramsData.userId }).then((res) => {
        this.socialList = res.data;
      });
    },
    handleDetailSocial(row) {
      getSocialDetail({
        recordId: row.recordId,
        classId: this.paramsData.classId,
      }).then((res) => {
        this.detailForm = res.data;
        this.open = true;
        if (this.detailForm.practiceImg) {
          this.detailForm.practiceImg = this.detailForm.practiceImg.split(",");
        }
      });
    },
    handleUnderstand(row) {
      if (row.submitStatus == "未写") {
        this.$message.warning("该生还未提交心得感悟");
      } else {
        getExperienceDetail({
          recordId: row.recordId,
          classId: row.classId,
          userId: row.userId,
        }).then((res) => {
          this.form = res.data;
          if (!this.isEmpty(this.form.perImg)) {
            this.form.perImg = this.form.perImg.split(",");
          }
          this.form.baseScore = this.form.baseScore / 2;
          this.openPub = true;
          this.rowForm = row;
        });
      }
    },
    handlereject() {
      if(this.form.rejectCause) {
        rejectExperience({
          recordId: this.rowForm.recordId,
          classId: this.rowForm.classId,
          userId: this.rowForm.userId,
          rejectCause: this.form.rejectCause,
          userCreditReduceScore: this.form.userCreditReduceScore
        }).then((res) => {
          this.$message.success("驳回成功");
          this.innerVisiblePub = false;
          this.openPub = false;
          this.getList();
        });        
      }else {
        this.$message.warning('请填写驳回原因！')
      }
    },
    backIndex() {
      this.$router.go(-1);
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #91939A;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/studentResult.scss";
.container {
  .main {
    .table-box {
      table {
        tr {
          th {
            width: 14%;
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.user-nav .el-select >>> .el-input {
  margin: 10px 12px;
}
.el-form-item {
  margin-bottom: 12px;
}
.el-form-item >>> .el-form-item__label {
  font-weight: bold;
  color: #303133;
}
/* .el-image {
  width: 300px;
} */
</style>