import { render, staticRenderFns } from "./socialStudent.vue?vue&type=template&id=0b94b1e6&scoped=true&"
import script from "./socialStudent.vue?vue&type=script&lang=js&"
export * from "./socialStudent.vue?vue&type=script&lang=js&"
import style0 from "./socialStudent.vue?vue&type=style&index=0&id=0b94b1e6&lang=scss&scoped=true&"
import style1 from "./socialStudent.vue?vue&type=style&index=1&id=0b94b1e6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b94b1e6",
  null
  
)

export default component.exports